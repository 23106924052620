<template>
  <Member
    title="Dr. Giovanni Tonon"
    description="Direttore Centro di Scienze Omiche al San Raffaele"
    text="Il dr. Giovanni Tonon è il direttore del Centro di Genomica Traslazionale e Bioinformatica, e del laboratorio di Genomica Funzionale del Cancro al San Raffaele di Milano. Il cuore della sua ricerca riguarda l’identificazione di geni coinvolti nel cancro attraverso approcci genomici e bioinformatici, la definizione del meccanismo tramite il quale questi geni inducono tumori e la traduzione di questi risultati in nuove terapie. Ha contribuito all’identificazione di geni del tumore del sangue mieloma multiplo, del polmone e del colon. Il suo laboratorio sta lavorando per l’identificazione di nuovi farmaci per debellare tumori in cui tali geni risultano alterati.

Come direttore del Centro di Genomica Traslazionale e Bioinformatica dell’Ospedale San Raffaele, il dr. Tonon coordina l’attività biologi, medici, fisici, bioinformatici, computational scientists, assistendo l’attività scientifica di tutto l’istituto. I progetti spaziano dall’oncologia alla neurologia, dalla medicina rigenerativa alla cardiologia, dall’immunologia alle malattie metaboliche, e utilizzano le tecniche più avanzate di genomica, incluse single-cell genomics, RNA-seq, ATAC-seq e vari approcci di sequenziamento del DNA, inclusi pannelli diagnostici."
    :formation="[
      'Liceo Tito Livio, Padua, Italy',
      'University of Padua, Padua, Italy',
      'Specializzazione, University of Milan, Italy',
      'Platelet Biology Department, American Red Cross, Rockville, MD',
      'University of Milan, Milan, Italy',
      'Genetics Branch, National Cancer Institute, Bethesda, MD',
      'Dana Farber Cancer Institute, Boston, MA',
    ]"
    publication=""
    :pub="false"
    :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Tonon",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
